var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "bold-text"
  }, [_vm._v("App Tokens")]), _vm.fromAppPage ? _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(" Select or create a new one ")]) : _vm._e(), !_vm.fromAppPage ? _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(" Designed to work with our SDK for in-app sessions & feedback. ")]) : _vm._e()]), _c('v-spacer'), !_vm.getInactiveUserMessage && _vm.canManageRelease ? _c('v-btn', {
    attrs: {
      "id": "createNewApiTokenBtn",
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showCreateForm = true, _vm.resetForm();
      }
    }
  }, [_vm._v(" Create App Token ")]) : _vm._e(), _vm.fromAppPage ? _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1) : _vm._e()], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "v-card v-sheet v-sheet--outlined",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.appTokenList,
      "loading": _vm.busy,
      "hide-default-footer": "",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.created_by",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('user-avatar', {
          attrs: {
            "user-detail": item.user
          }
        })];
      }
    }, {
      key: "item.token",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', {
          staticClass: "black--text cursor-pointer",
          attrs: {
            "color": "grey",
            "label": ""
          },
          on: {
            "click": function click($event) {
              return _vm.selectToken(item.token);
            }
          }
        }, [_c('MaxText', {
          attrs: {
            "text": item.token,
            "max": "15"
          }
        })], 1)];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })];
      }
    }, {
      key: "item.last_used",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.last_used ? _c('vue-hoverable-date', {
          attrs: {
            "date": item.last_used
          }
        }) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          class: item.status === 1 ? 'success--text' : 'error--text'
        }, [_vm._v(_vm._s(item.status === 1 ? "Active" : "Inactive"))])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [!_vm.getInactiveUserMessage && _vm.canManageRelease ? _c('ActionDropdown', {
          scopedSlots: _vm._u([{
            key: "list",
            fn: function fn() {
              return [_c('v-list-item', {
                attrs: {
                  "id": "renameNameOption",
                  "disabled": item.status !== 1
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.openRenameKeyModal(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("edit")]), _vm._v(" Rename Key ")], 1), _c('v-list-item', {
                attrs: {
                  "id": "removeTokenOption"
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeToken(item.id, item.name);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-delete")]), _vm._v(" Delete Key ")], 1)];
            },
            proxy: true
          }], null, true)
        }) : _vm._e()];
      }
    }, {
      key: "loading",
      fn: function fn() {
        return [_c('v-skeleton-loader', {
          attrs: {
            "type": "table-heading, list-item-two-line"
          }
        })];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('no-data', {
          attrs: {
            "first-text": 'No tokens... just yet!',
            "second-text": "App tokens are required to integrate with our SDK for in-app sessions & feedback.<br />You would need to enable Sessions first from the Settings page to create a token"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 650 : '',
      "no-py": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" Delete API Token")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "message"
        }, [_c('v-alert', {
          staticClass: "warning--text",
          attrs: {
            "color": "warning",
            "outlined": ""
          }
        }, [_vm._v(" Heads up! You're about to delete "), _c('b', {
          attrs: {
            "id": "selectedName"
          }
        }, [_vm._v(_vm._s(_vm.selectedName))]), _vm._v(" API Token which may used in TA-CLI or Github Action or similar integrations! ")]), _c('div', [_vm._v(" Deleting these credentials will break any integrations that use them. Please be sure to update those integrations before deleting. This action "), _c('b', [_vm._v("cannot")]), _vm._v(" be undone. ")]), _c('div', {
          staticClass: "my-3"
        }, [_vm._v(" If you're sure, please type in the exact name of the credentials to confirm. ")]), _c('div', [_c('v-text-field', {
          attrs: {
            "id": "confirmDeleteField",
            "filled": "",
            "hint": "This field is case sensitive.",
            "placeholder": "Name"
          },
          model: {
            value: _vm.confirmRemoveName,
            callback: function callback($$v) {
              _vm.confirmRemoveName = $$v;
            },
            expression: "confirmRemoveName"
          }
        })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancelRemoveModal
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "permanentDeleteToken",
            "disabled": _vm.selectedName !== _vm.confirmRemoveName,
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "error"
          },
          on: {
            "click": _vm.confirmRemove
          }
        }, [_vm._v("Permanently delete this credential ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.removeTokenModal,
      callback: function callback($$v) {
        _vm.removeTokenModal = $$v;
      },
      expression: "removeTokenModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.showCreateForm = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showCreateForm ? _c('v-card', {
          staticClass: "pa-3"
        }, [_c('v-card-title', [_vm._v(" Create App Token")]), _c('v-card-text', [_c('v-row', {
          attrs: {
            "id": "createNewTokenForm"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-text-field', _vm._b({
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required|min:3|max:27',
            expression: "'required|min:3|max:27'"
          }],
          attrs: {
            "id": "createTokenName",
            "counter": "27",
            "dense": "",
            "filled": "",
            "label": "Name",
            "placeholder": "Where you are going to use this token?"
          },
          model: {
            value: _vm.formValues.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formValues, "name", $$v);
            },
            expression: "formValues.name"
          }
        }, 'v-text-field', _vm.veeValidate('Name', ''), false))], 1), _c('v-col', {
          staticClass: "py-0 text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "mx-2 text-transform-none",
          attrs: {
            "id": "createTokenCancel",
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.cancelCreateForm
          }
        }, [_vm._v("Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "createTokenSubmit",
            "disabled": _vm.errors.any(),
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.createAPIToken
          }
        }, [_vm._v("Create ")])], 1)], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showCreateForm,
      callback: function callback($$v) {
        _vm.showCreateForm = $$v;
      },
      expression: "showCreateForm"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "click": function click($event) {
        _vm.renameKeyModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.renameKeyModal ? _c('v-card', {
          staticClass: "pa-3",
          attrs: {
            "id": "renameField",
            "flat": "",
            "tile": ""
          }
        }, [_c('v-card-title', [_vm._v(" Rename key")]), _c('v-card-text', [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-text-field', _vm._b({
          directives: [{
            name: "validate",
            rawName: "v-validate",
            value: 'required|max:27',
            expression: "'required|max:27'"
          }],
          attrs: {
            "id": "changeNameField",
            "filled": "",
            "placeholder": "Name"
          },
          model: {
            value: _vm.changeText,
            callback: function callback($$v) {
              _vm.changeText = $$v;
            },
            expression: "changeText"
          }
        }, 'v-text-field', _vm.veeValidate('Name', ''), false))], 1), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "mx-3 text-transform-none",
          attrs: {
            "disabled": _vm.loading,
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.renameKeyModal = false;
            }
          }
        }, [_vm._v("Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "changeNameBtn",
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": _vm.changeName
          }
        }, [_vm._v("Save ")])], 1)], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.renameKeyModal,
      callback: function callback($$v) {
        _vm.renameKeyModal = $$v;
      },
      expression: "renameKeyModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }